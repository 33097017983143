import React, {useEffect} from 'react'
import {compose} from 'recompose';
import {inject,observer} from 'mobx-react'
import ExploreHeader from '@src/components/layout/ExploreHeader'
import ExploreSearchBar from '@src/components/ExploreSearchBar'
import ExploreMap from '@src/components/map/ExploreMap'
import MapPropertyList from '@src/components/property/MapPropertyList'
import { hBox,vBox } from "gx-web-ui/utils/flex";
import MePanel from '@src/components/setting/MePanel'
import TabletMenu from '@src/components/tablet/TabletMenu'
import LandingPageOptions from '@src/components/LandingPageOptions'
import {
    isTablet,
    isMobile
} from "react-device-detect";
import withRoot from '../withRoot'

// import { create,GenerateId, JssProvider } from 'jss';
// import { StylesProvider, jssPreset } from '@material-ui/core/styles';

// const jss = create({
//   ...jssPreset(),
//   // 当将样式注入到 DOM 中时，定义了一个自定义插入点以供 JSS 查询。 
//   insertionPoint: 'jss-insertion-point',
// });


const Explore = (props)=> {
    const {uiStore} = props
    useEffect(() => {
        uiStore.setExplorePageCurrentTab('explore')
    }, [])
    return (
            <div style={{position:'relative'}}>
                {!isMobile &&
                <ExploreHeader fullWidth />
                }
                <div style={{position:'relative'}}>
                    <MePanel/>
                    <div style={{position:'relative', height:'calc(100vh - 64px)',backgroundColor:'white'}}>
                        <div>
                            {isMobile &&
                            <TabletMenu/>
                            }
                            <ExploreSearchBar/>
                            <div style={{...hBox('stretch')}}>
                                <ExploreMap/>
                                <MapPropertyList/>
                            </div>
                        </div>
                    </div>
                    <div style={{
                        margin:32,
                        marginLeft:64,
                        marginRight:64,
                        marginBottom:0
                    }}>
                        <LandingPageOptions/>
                    </div>
                </div>
            </div>
        )
}

export default compose(withRoot,inject('uiStore'),observer)(Explore)
